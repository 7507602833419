<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="toefl_title" label="Test" width="120px">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="viewTranscript(scope.row.user_exam_id)"
          >
            <i class="fa fa-eye"></i> {{ scope.row.toefl_title }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Student"> </el-table-column>
      <el-table-column prop="total_score" label="Score" width="80">
      </el-table-column>
      <el-table-column prop="created_at" label="Date">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column prop="is_finished" label="Status">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_finished === 0 ? 'danger' : 'success'"
            disable-transitions
          >
            <span v-if="scope.row.is_finished === 0">未完成</span>
            <span v-else>完成</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Update" width="100">
        <template slot-scope="scope">
          <el-button
            class="updateScore"
            type="text"
            @click="updateScore(scope.row.user_exam_id)"
            ><i class="fas fa-cloud-upload-alt"></i
          ></el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Combine" width="120">
        <template slot-scope="scope">
          <a  v-if="scope.row.is_finished === 1" :href="'/toefl/testresults/combine?uid=' + scope.row.uid + '&tpo=' + scope.row.toefl_title">
            <el-button type="success" size="small">
              <i class="far fa-object-group"></i> 合併
            </el-button>
          </a>
        </template>
      </el-table-column> -->
      <el-table-column label="Actions" fixed="right" width="120">
        <template slot-scope="scope">
          <a
            :href="
              '/toefl/test?id=' +
                scope.row.exam_id +
                '&mode=0&user_exam_id=' +
                scope.row.user_exam_id
            "
            v-if="scope.row.is_finished === 0"
          >
            <el-button type="success" size="small">
              <i class="el-icon-video-play"></i> 繼續
            </el-button>
          </a>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="Actions" label="Actions"> </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import TOEFL from "@/apis/toefl";

export default {
  components: {},

  mixins: [],

  props: ["tableData"],
  data() {
    return {
      moment
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    viewTranscript(id) {
      this.routerPush({
        name: "Transcript",
        query: {
          id
        }
      });
    },
    async updateScore(id) {
      await TOEFL.updateScore(id);
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.updateScore {
  font-size: 18px;
}
</style>
