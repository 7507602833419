<template>
  <div class="container">
    <el-menu
      default-active="2"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1"
        ><b>{{ $t("toefl.TOEFL_Practices") }}</b></el-menu-item
      >
      <el-menu-item index="2"
        ><b>{{ $t("toefl.TOEFL_Results") }}</b></el-menu-item
      >
    </el-menu>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="9"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <a :href="'/toefl/testresults/combine'">
      <el-button type="success" size="small">
        <i class="far fa-object-group"></i> 合併你已經完成的考卷
      </el-button>
    </a>
    <el-tabs v-model="type" @tab-click="handleClick" style="margin-top:20px">
      <el-tab-pane label="Mock" name="full_mock">
        <mock :tableData="tableData"></mock>
      </el-tab-pane>
      <el-tab-pane label="Quick" name="quick_mock">
        <mock :tableData="tableData"></mock>
      </el-tab-pane>
      <el-tab-pane label="Reading" name="reading">
        <section-test :tableData="tableData"></section-test>
      </el-tab-pane>
      <el-tab-pane label="Listening" name="listening">
        <section-test :tableData="tableData"></section-test>
      </el-tab-pane>
      <el-tab-pane label="Speaking" name="speaking">
        <section-test :tableData="tableData"></section-test>
      </el-tab-pane>
      <el-tab-pane label="Writing" name="writing">
        <section-test :tableData="tableData"></section-test>
      </el-tab-pane>
      <el-tab-pane label="Single Section" name="single_section">
        <section-test :tableData="tableData"></section-test>
      </el-tab-pane>
    </el-tabs>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="9"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/toefl.js";
import Mock from "@/views/toefl/toeflTestResults/Mock.vue";
import SectionTest from "@/views/toefl/toeflTestResults/SectionTest.vue";

export default {
  metaInfo() {
    return {
      title: "Test Results - Ivy-Way Academy"
    };
  },

  components: {
    Mock,
    SectionTest
  },

  mixins: [],

  props: [],
  data() {
    return {
      activeName: "Mock",
      tableData: [],
      results: [],
      resultsTotal: 0,
      per_page: 0,
      pageSize: 0,
      type: "full_mock"
    };
  },
  computed: {
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getResults();
    this.type = this.getType();
  },

  methods: {
    async getResults() {
      let type = this.getType();
      const tableData = await TOEFL.getAllTranscripts({
        category: type,
        page: this.page
      });
      this.tableData = tableData.data;
      this.resultsTotal = tableData.total;
      this.pageSize = tableData.per_page;
    },
    handleClick(e) {
      this.$router.replace({
        name: "ToeflTestResults",
        query: {
          type: e.name,
          page: 1
        }
      });
    },
    getType() {
      let type = "full_mock";
      if (this.$route.query.type === undefined) {
        type = "full_mock";
      } else {
        type = this.$route.query.type;
      }
      return type;
    },
    handleSelect(index) {
      if (index === "1") {
        this.$router.replace({
          name: "ToeflList"
        });
      }
    },
    setPage(page) {
      this.routerPush({
        name: "ToeflTestResults",
        query: {
          type: this.getType(),
          page: page
        }
      });
    }
  }
};
</script>

<style scoped>
.ivyway-title {
  margin: 20px 0;
}
</style>
